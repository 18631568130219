import './App.css'
import Navbar from './Navbar'
// import Homepage from './Homepage'
import About from './About'

function App() {
  return (
    <div className='App'>
      <Navbar />
      <main>
        {/* <Homepage /> */}
        <About />
      </main>
    </div>
  )
}

export default App
