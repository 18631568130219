import React from 'react'
import styles from './About.module.css'

const About = () => {
  return (
    <section className={styles.container}>
      <div className={styles.leftColumn}>
        <p className={styles.bioShort}>
          <span className={styles.massiveFont}> Piotr Opala</span> is a Berlin
          based pianist and composer known for creating melancholic, dreamlike
          piano music and evocative soundscapes. Blending delicate melodies with
          atmospheric textures, Piotr's work invites listeners into a world of
          quiet introspection and beauty.
        </p>
      </div>
      <div className={styles.rightColumn}>
        <img className={styles.image} src='/piotrOpala.jpg' alt='Description' />
      </div>
    </section>
  )
}

export default About
