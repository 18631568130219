import * as React from "react";

const SvgIcon = ({ height, width, color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="-0.5 0 25 25"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M2 12.32h20M2 18.32h20M2 6.32h20"
    ></path>
  </svg>
);

export default SvgIcon;
