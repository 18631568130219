import { useState } from 'react'
import useWindowSize from './hooks/useWindowSize'
import styles from './Navbar.module.css'
import Instagram from './SVG/Instagram'
import Hamburger from './SVG/Hamburger'
import Facebook from './SVG/Facebook'

const iconProps = {
  height: '24px',
  width: '24px',
  color: 'white',
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const windowSize = useWindowSize()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const tabletOrSmaller = windowSize < 768

  const LinksList = () => {
    return (
      <nav>
        <ul className={styles.navLinks}>
          <li className={styles.linkNavigation}>
            <a href='https://platoon.lnk.to/inceptio-piotr-opala!share'>
              Listen Now
            </a>
            <a href='#About'>About</a>
            <a href='https://platoon.lnk.to/inceptio-piotr-opala!share'>
              Works
            </a>
            {/* <a href='#Contact'>Contact</a> */}
          </li>
          <li>
            <p
              style={{
                color: 'grey',
              }}
            >
              Contact:{' '}
              <a href='mailto: piotropala.music@gmail.com'>
                piotropala.music@gmail.com
              </a>
            </p>
          </li>

          <li
            className={styles.linkSocials}
            style={{
              color: 'grey',
            }}
          >
              <a href='https://www.instagram.com/piotr__opala'>
                <Instagram {...iconProps} />
              </a>
              <a href='https://www.facebook.com/profile.php?id=61568566993143'>
                <Facebook {...iconProps} />
              </a>
          </li>
        </ul>
      </nav>
    )
  }
  return (
    <header className={styles.header}>
      <div className={styles.navbar}>
        <div className={styles.linkHome}>
          <h1 className={styles.pageTitle}>
            <a href='/'>Piotr Opala</a>
          </h1>
        </div>

        {tabletOrSmaller ? (
          <div
            className={styles.navToggler}
            onClick={() => toggleMenu()}
          >
            <Hamburger {...iconProps} />
          </div>
        ) : (
          <LinksList />
        )}
      </div>

      {isOpen && (
        <div className={styles.mobileNav}>
          <LinksList />
        </div>
      )}
    </header>
  )
}

export default Navbar
